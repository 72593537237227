<template>
    <div class="product-body main home-main">
        <QsNavbar title="个人中心"></QsNavbar>
        <van-cell title="我的收藏" icon="like-o" is-link to="/my/home_product_list?tag=fav" />
        <van-cell title="浏览记录" icon="clock-o" is-link to="/my/home_product_list?tag=history" />
        <QsTabbar tab-id="my" group="product"></QsTabbar>
    </div>
</template>

<script>

import { mapState,mapGetters } from 'vuex'


export default {
    data(){
        return{
            histories:[],
            favs:[],
            activeName:""
        }
    },
    mounted(){
        // this.onLoad();
    },
    methods:{
        // onLoad(){
        //     this.$ajax.get("client/my/product_home").then(res=>{
        //         this.histories = res.data.histories;           
        //         this.favs = res.data.favs;
        //     }).catch(e=>{})
        // }
    },
    components: {
        
    },
    computed:{
        // ...mapState({
        //     user:state=>state.user
        // }),
        // ...mapGetters(['isLogin','isSubscribe','isRegister']),
    }    
}
</script>


<style lang="scss" scoped>
.product-list ::v-deep .product-box:last-of-type .product-item {border-bottom:none;}
.van-icon{color: rgb(99, 199, 183) !important;}
</style>

